@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";

.sidebar-logo {
  height: 50px;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.layout-bottom {
  margin-bottom: 40px;
  flex: 1 0 auto;
}

.footer-layout {
  flex-shrink: 0;
}

a {
  text-decoration: none;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagenotfoundcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.MuiDrawer-paper {
  border: none !important;
  background-color: rgba(243, 246, 249, 0.6) !important;
}

.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MuiDataGrid-footerContainer {
  display: none !important;
}
.MuiDataGrid-virtualScroller {
  /* height: 530px !important; */
  min-height: 100px;
}